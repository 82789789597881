const allStories = [
  {
    id: "01",
    name: "Офариниши Олам",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/01-Офариниши-олам.mp3"
  },
  {
    id: "02",
    name: "Худо Гуноҳҳоро Ҳукм Мекунад",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/02-Худо-гуноҳоро-ҳукм-мекунад.mp3"
  },
  {
    id: "03",
    name: "Қобил ва Ҳобил",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/03-Қобил-ва-Ҳобил.mp3"
  },
  {
    id: "04",
    name: "Нуҳ ва Тӯфон",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/04-Нуҳ-ва-тӯфон.mp3"
  },
  {
    id: "05",
    name: "Қурбонии Иброҳим",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/05-Қурбонии-Иброҳим.mp3"
  },
  {
    id: "06",
    name: "Садӯм ва Амӯро",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/06-Садӯм-ва-Амӯро.mp3"
  },
  {
    id: "07",
    name: "Хуни Барра барои Наҷот",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/07-Хуни-барра-барои-наҷот.mp3"
  },
  {
    id: "08",
    name: "Қонунҳои Худованд",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/08-Қонунҳои-Худованд.mp3"
  },
  {
    id: "09",
    name: "Хаймаи Худованд",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/09-Хаймаи-Худованд.mp3"
  },
  {
    id: "10",
    name: "Тавбаи Подшоҳ Довуд",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/10-Тавбаи-подшоҳ-Довуд.mp3"
  },
  {
    id: "11",
    name: "Подшоҳ Сулаймон",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/11-Подшоҳ-Сулаймон-1.mp3"
  },
  {
    id: "12",
    name: "Ишаъё Пайғамбар",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/12-Ишаъё-пайгамбар-1.mp3"
  },
  {
    id: "13",
    name: "Юунус Пайғамбар",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/13-Юнус-пайгамбар.mp3"
  },
  {
    id: "14",
    name: "Омадани Наҷотдиҳанда",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/14-Омадани-Начотдиханда.mp3"
  },
  {
    id: "15",
    name: "Озмоиши Исо",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/15-Озмоиши-Исо.mp3"
  },
  {
    id: "16",
    name: "Даъват ва Таъин Кардани Шогирдон",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/16-Даъват-ва-таъин-кардани-шогирдон.mp3"
  },
  {
    id: "17",
    name: "Сухбати Исо ба Никудимус",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/17-Сухбати-Исо-бо-Никудимус.mp3"
  },
  {
    id: "18",
    name: "Исо ва Зани Сомарӣ",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/18-Исо-ва-зани-Сомари.mp3"
  },
  {
    id: "19",
    name: "Исо буморонро Шифо Медиҳад",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/19-Исо-беморонро-шифо-медиҳад.mp3"
  },
  {
    id: "20",
    name: "Суханронии Исо дар болои Кӯҳ 1",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/20-Суханронии-Исо-дар-болои-кух-кисми-1.mp3"
  },
  {
    id: "21",
    name: "Сунанронии Исо дар болои Кӯҳ 2",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/21-Суханронии-Исо-дар-болои-кух-кисми-2.mp3"
  },
  {
    id: "22",
    name: "Сомарии Рахмдил",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/22-Сомарии-рахмдил.mp3"
  },
  {
    id: "23",
    name: "Исо Чупони Некдил",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/23-Исо-чупони-некдил.mp3"
  },
  {
    id: "24",
    name: "Писари Гапнодаро",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/24-Писари-Гапнодаро.mp3"
  },
  {
    id: "25",
    name: "Козии Ноинсоф ва Дуст Хира",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/25-Козии-ноинсоф-ва-дусти-хира.mp3"
  },
  {
    id: "26",
    name: "Ҷавони Сарватманд",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/26-Чавони-сарватманд.mp3"
  },
  {
    id: "27",
    name: "Исо Пои Шогирдонашро мешуяд",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/27-Исо-пои-шогирдонашро-мешуяд.mp3"
  },
  {
    id: "28",
    name: "Шоми Худованд ва ваъдаи Рӯҳи Мукаддас",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/28-Шоми-Худованд-ва-ваъдаи-Рухи-Мукаддас.mp3"
  },
  {
    id: "29",
    name: "Дуо дар Боғи Ҷатсамонӣ",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/29-Дуо-дар-боги-Чатсамони.mp3"
  },
  {
    id: "30",
    name: "Хукмшавии Исо",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/30-Хукмшавии-Исо.mp3"
  },
  {
    id: "31",
    name: "Марги Исо",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/31-Марги-Исо-1.mp3"
  },
  {
    id: "32",
    name: "Эҳёшавии Исо",
    src:
      "https://taalimotisolim.com/wp-content/uploads/2019/08/32-Эхешавии-Исо-1.mp3"
  }
];
export default allStories;
