import React from "react";
//import kitobThumb from "../images/kitobThumb"

function StoryCard(props) {
  //
  return (
    <div className="card-div" onClick={props.onClick}>
      <h4>
        {props.id}. {props.name}
      </h4>
    </div>
  );
}

export default StoryCard;
