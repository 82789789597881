import React from "react";

const Menu = () => (
  <ul className="menu">
    <li>
      <a href="https://taalimotisolim.com/">Таълимоти Солим</a>
    </li>
    <li>
      <a href="http://hikoyaho.taalimotisolim.com/" className="active">
        Ҳикояҳои Чашмаи Ҳаёт
      </a>
    </li>
    <li>
      <a href="https://musiqi.taalimotisolim.com/">Мусиқӣ</a>
    </li>
    <li>
      <a href="http://nigohbakitob.taalimotisolim.com/">Нигоҳ ба Китоб</a>
    </li>
  </ul>
);

export default Menu;
