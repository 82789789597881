import React from "react";
import Menu from "./Menu";
//import AudioPlayer from "./AudioPlayer";
import StoryCard from "./StoryCard";
import storyList from "./storyList";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      chosenStory: "01",
      stories: storyList
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(val) {
    console.log(val);
    this.setState({ chosenStory: val }, function() {
      this.refs.audio.pause();
      this.refs.audio.load();
      this.refs.audio.play();
    });
  }

  render() {
    const allStoryCards = storyList.map(story => (
      <StoryCard
        id={story.id}
        name={story.name}
        source={story.src}
        onClick={() => this.handleClick(story.id)}
      />
    ));

    //find the one chosen story
    const myStory = this.state.stories.find(
      story => story.id === this.state.chosenStory
    );

    return (
      <div>
        <div className="menu-div">
          <Menu />
        </div>
        <div className="top">
          <h1 className="title">Ҳикояҳои Чашмаи Ҳаёт</h1>
        </div>
        <div className="main-app">
          {/*
          <AudioPlayer id={myStory.id} 
            name={myStory.name}
            src={myStory.src}/>
        */}
        </div>
        <div className="audio-div">
		<h1>{myStory.name}</h1>
          <audio controls ref="audio">
            <source src={myStory.src} />
          </audio>
        </div>
        <div className="story-list">{allStoryCards}</div>
      </div>
    );
  }
}

export default App;
